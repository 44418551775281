.CalendarDay {
  border: none;
  padding: 0;
  box-sizing: border-box;
  color: #565a5c;
  cursor: pointer;
  width: 39px;
  height: 38px;
  vertical-align: middle;
  position: relative;
}
.CalendarDay:active {
  background: #f2f2f2;
}
.CalendarDay--highlighted-calendar {
  background: #ffe8bc;
  color: #565a5c;
  cursor: default;
}
.CalendarDay--highlighted-calendar:active {
  background: #fff5bd;
}
.CalendarDay--outside {
  cursor: default;
}
.CalendarDay--outside:active {
  background: #fff;
}
.CalendarDay--hovered {
  background: #e4e7e7;
  color: inherit;
}
.CalendarDay--blocked-minimum-nights {
  color: #cacccd;
  background: #fff;
  cursor: default;
}
.CalendarDay--blocked-minimum-nights:active {
  background: #fff;
}
.CalendarDay--selected-span {
  background: #fff5bd;
  color: #555555;
}
.CalendarDay--selected-span.CalendarDay--hovered,
.CalendarDay--selected-span:active {
  background: #f9e053;
}
.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #fff5bd;
  border: none;
  color: #000000;
}
.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  background: #f9e053;
}
.CalendarDay--selected-start,
.CalendarDay--selected-end {
  border-radius: 50%;
}
.CalendarDay--selected-start:active,
.CalendarDay--selected-end:active,
.CalendarDay--selected:active {
  background: #e4a100;
}
.CalendarDay--blocked-calendar {
  background: #cacccd;
  color: #82888a;
  cursor: default;
}
.CalendarDay--blocked-calendar:active {
  background: #cacccd;
}
.CalendarDay--blocked-out-of-range {
  color: #cacccd;
  background: #fff;
  border: none;
  cursor: default;
}
.CalendarDay--blocked-out-of-range:active {
  background: #fff;
}
.CalendarMonth {
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth:first-of-type {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonth table {
  border-collapse: collapse;
  border-spacing: 0;
  caption-caption-side: initial;
}
.CalendarMonth--horizontal {
  display: inline-block;
  min-height: 100%;
}
.CalendarMonth--vertical {
  display: block;
}
.CalendarMonth__caption {
  margin-top: 7px;
  font-size: 14px;
  font-weight: normal;
  color: #555555;
  text-align: center;
  margin-bottom: 2px;
  caption-side: initial;
}
.CalendarMonth--horizontal .CalendarMonth__caption,
.CalendarMonth--vertical .CalendarMonth__caption {
  padding: 15px 0 35px;
}
.CalendarMonth--vertical-scrollable .CalendarMonth__caption {
  padding: 5px 0;
}
.CalendarMonthGrid {
  background: #fff;
  z-index: 0;
  text-align: left;
}
.CalendarMonthGrid--animating {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}
.CalendarMonthGrid--horizontal {
  position: absolute;
  left: 9px;
  width: 1200px;
}
.CalendarMonthGrid--vertical {
  width: 300px;
  margin: 0 auto;
}
.CalendarMonthGrid--vertical-scrollable {
  width: 300px;
  margin: 0 auto;
  overflow-y: scroll;
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}
.DayPicker * {
  outline: none !important;
}
.DayPicker--horizontal {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding-top: 6px;
}
.DayPicker--horizontal.DayPicker--portal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker--vertical.DayPicker--portal {
  position: initial;
}
.DayPicker__week-headers {
  position: relative;
}
.DayPicker--horizontal .DayPicker__week-headers {
  margin-left: 9px;
}
.DayPicker__week-header {
  color: #757575;
  position: absolute;
  width: 300px;
  top: 50px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
  font-weight: bold;
}
.DayPicker__week-header ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
}
.DayPicker__week-header li {
  display: inline-block;
  width: 39px;
  text-align: center;
}
.DayPicker--vertical .DayPicker__week-header {
  margin-left: -150px;
  left: 50%;
}
.DayPicker--vertical-scrollable {
  height: 100%;
}
.DayPicker--vertical-scrollable .DayPicker__week-header {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: white;
}
.DayPicker--vertical-scrollable .transition-container--vertical {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.DayPicker--vertical-scrollable .DayPicker__week-header {
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.transition-container {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.transition-container--horizontal {
  transition: height 0.2s ease-in-out;
}
.transition-container--vertical {
  width: 100%;
}
.DayPickerNavigation__prev,
.DayPickerNavigation__next {
  cursor: pointer;
  line-height: 0.78;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #f9e053;
  color: #555555;
  text-align: center;
}
.DayPickerNavigation__prev--default,
.DayPickerNavigation__next--default {
  border: none;
  background-color: #fff;
  color: #757575;
}
.DayPickerNavigation__prev--default:focus,
.DayPickerNavigation__prev--default:hover,
.DayPickerNavigation__next--default:focus,
.DayPickerNavigation__next--default:hover {
  border: none;
}
.DayPickerNavigation__prev--default:active,
.DayPickerNavigation__next--default:active {
  background: #f2f2f2;
}
.DayPickerNavigation--horizontal {
  position: relative;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev,
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  padding: 6px 0;
  top: 18px;
  z-index: 2;
  position: absolute;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev {
  left: 22px;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  right: 22px;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
  height: 19px;
  width: 19px;
  fill: #82888a;
}
.DayPickerNavigation--vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
  z-index: 2;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev,
.DayPickerNavigation--vertical .DayPickerNavigation__next {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  border-left: 0;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  text-align: center;
  font-size: 2.5em;
  padding: 5px;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPickerNavigation--vertical-scrollable {
  position: relative;
}
.DayPickerNavigation--vertical-scrollable .DayPickerNavigation__next {
  width: 100%;
}
.DateInput {
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.4px;
  padding: 0;
  line-height: 24px;
  color: #757575;
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.DateInput--with-caret::before,
.DateInput--with-caret::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: auto;
  border: 10px solid transparent;
  border-top: 0;
  left: 22px;
  z-index: 2;
}
.DateInput--with-caret::before {
  top: 24px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.DateInput--with-caret::after {
  top: 25px;
  border-bottom-color: #fff;
}
.DateInput--disabled {
  background: #cacccd;
}
.DateInput__input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  height: 100%;
  width: 100%;
}
.DateInput__input[readonly] {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput__display-text {
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
}
.DateInput__display-text--has-input {
  color: #484848;
}
.DateInput__display-text--focused {
  border-color: black;
  border-radius: 5px;
}
.DateInput__display-text--disabled {
  font-style: italic;
}
.screen-reader-only {
  border: none;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateRangePicker {
  position: relative;
  display: inline-block;
  font-size: 14px;
}
.DateRangePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 34px;
  border-radius: 5px;
}
.DateRangePicker__picker--direction-left {
  left: 0;
}
.DateRangePicker__picker--direction-right {
  right: 0;
}
.DateRangePicker__picker--portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker__picker--full-screen-portal {
  background-color: #fff;
}
.DateRangePicker__close {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.DateRangePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DateRangePicker__close:hover,
.DateRangePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.DateRangePickerInput {
  border-radius: 100vmax;
  border: none;
  background-color: #e4a100;
  text-align: center;
  width: 213px;
  height: 24px;
  display: inline-block;
  position: relative;
  padding-left: 20px;
}
.DateRangePickerInput--disabled {
  background: #cacccd;
}
.DateRangePickerInput__arrow {
  display: inline-block;
  vertical-align: middle;
  padding: 0 3px;
  font-size: 10px;
}
.DateRangePickerInput__arrow svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput__clear-dates {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.DateRangePickerInput__clear-dates svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput__clear-dates--hide {
  visibility: hidden;
}
.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput__calendar-icon {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  line-height: inherit;
  height: 100%;
  position: absolute;
  left: 16px;
}
.DateRangePickerInput__calendar-icon svg {
  vertical-align: top;
  margin-right: 6px;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 72px;
}
.SingleDatePicker__picker--direction-left {
  left: 0;
}
.SingleDatePicker__picker--direction-right {
  right: 0;
}
.SingleDatePicker__picker--portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker__picker--full-screen-portal {
  background-color: #fff;
}
.SingleDatePicker__close {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.SingleDatePicker__close:hover,
.SingleDatePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.SingleDatePickerInput {
  background-color: #fff;
  border: none;
}
.SingleDatePickerInput__clear-date {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.SingleDatePickerInput__clear-date svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput__clear-date--hide {
  visibility: hidden;
}
.SingleDatePickerInput__clear-date:focus,
.SingleDatePickerInput__clear-date--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.CalendarDay--selected-start::after {
  width: 50%;
  content: "";
  background-color: #fff5bd;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
}
.CalendarDay--selected-end::after {
  width: 50%;
  content: "";
  background-color: #fff5bd;
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0;
  z-index: -1;
}
.CalendarDay--selected-start.CalendarDay--selected-end::after {
  width: 0;
  content: "";
  background-color: transparent;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
