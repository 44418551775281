body {
  margin: 0;
  font-family: HKNova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.search-block {
  background-color: #f9e053;
  padding: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.route-summary,
.loadSummary {
  background-color: #f9e053;
  font-size: 14px;
  padding: 5px 14px 11px 14px;
}
.DayPickerNavigation__prev,
.DayPickerNavigation__next,
.CalendarMonth button {
  border: 0;
  background-color: transparent;
}
.DayPickerKeyboardShortcuts__show {
  display: none;
}
