/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 10000;
  height: 1px;
  width: 1px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, #a3ccff 0%, transparent 160px);
}
.loading:after {
  content: '';
  display: block;
  position: relative;
  z-index: 10001;
  font-size: 10px;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  background-image: url("../../assets/images/preloader_small.gif");
}
